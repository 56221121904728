import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <div className="error">
      <p className="error-status">404</p>
      <p className="error-text">{t('pages.error.error_text')}</p>
      <a className="error-link" href="/">
        {t('pages.error.link_to_main')}
      </a>
    </div>
  );
};

export default ErrorPage;
